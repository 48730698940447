// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

function scrollY() {
  return window.scrollY;
}

function scrollX() {
  return window.scrollX;
}

function setScroll(x, y) {
  window.scroll(x, y);
}

function scrollPosition() {
  return [
          window.scrollX,
          window.scrollY
        ];
}

function scrollToTop() {
  setScroll(0, 0);
}

function toJs(v) {
  return {
          behavior: v.behavior,
          block: v.block
        };
}

var Options = {
  toJs: toJs
};

function scrollToHash(hash, options, param) {
  var $$location = hash !== undefined ? hash : window.location.hash;
  if ($$location === "") {
    return ;
  }
  var match = document.querySelector($$location);
  if (!(match == null)) {
    if (options !== undefined) {
      match.scrollIntoView(toJs(options));
    } else {
      match.scrollIntoView();
    }
    return ;
  }
  
}

function isElementInView(element) {
  var boundingRect = element.getBoundingClientRect();
  if (boundingRect.top >= 0 && boundingRect.left >= 0 && boundingRect.right <= window.innerWidth) {
    return boundingRect.bottom <= window.innerHeight;
  } else {
    return false;
  }
}

function scrollToHashIfNeeded(hash, options, param) {
  var $$location = hash !== undefined ? hash : window.location.hash;
  if ($$location === "") {
    return ;
  }
  var match = Core__Option.map(Caml_option.nullable_to_opt(document.querySelector($$location)), (function (el) {
          return [
                  el,
                  isElementInView(el)
                ];
        }));
  if (match === undefined) {
    return ;
  }
  if (match[1]) {
    return ;
  }
  var el = match[0];
  if (options !== undefined) {
    el.scrollIntoView(toJs(options));
  } else {
    el.scrollIntoView();
  }
}

var $$Location;

exports.$$Location = $$Location;
exports.scrollY = scrollY;
exports.scrollX = scrollX;
exports.setScroll = setScroll;
exports.scrollPosition = scrollPosition;
exports.scrollToTop = scrollToTop;
exports.Options = Options;
exports.scrollToHash = scrollToHash;
exports.isElementInView = isElementInView;
exports.scrollToHashIfNeeded = scrollToHashIfNeeded;
/* No side effect */
