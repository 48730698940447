// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var AllImages = require("./AllImages.bs.js");
var ImageGrid = require("./ImageGrid.bs.js");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var MediaQuery = require("../../../reason/common/Media/MediaQuery.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var MarkupHelpers = require("../../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var TMPUIKIT____ImgGallery = require("../../../temporaryUIKit/TMPUIKIT____ImgGallery/TMPUIKIT____ImgGallery.bs.js");
var ImageGallery_image_graphql = require("../../../__generated__/ImageGallery_image_graphql.bs.js");

var convertFragment = ImageGallery_image_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, ImageGallery_image_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ImageGallery_image_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(fRef) : undefined, ImageGallery_image_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(ImageGallery_image_graphql.node, convertFragment, fRef);
}

var ImageFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function ImageGallery$MobileViewAllButton(props) {
  return JsxRuntime.jsx("button", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(Icon__Jsx3.make, {
                            icon: "Picture",
                            size: 16
                          }),
                      JsxRuntime.jsx("div", {
                            children: "View all photos"
                          })
                    ],
                    className: "flex flex-row items-center gap-2"
                  }),
              className: "bg-tan-blue-500 absolute cursor-pointer text-white rounded px-4 py-1.5 font-medium bottom-[-12px] right-[4px]",
              onClick: props.onPress
            });
}

var MobileViewAllButton = {
  make: ImageGallery$MobileViewAllButton
};

function ImageGallery(props) {
  var images = use(props.images);
  var match = React.useState(function () {
        return false;
      });
  var setIsAllImagesOpen = match[1];
  var isAllImagesOpen = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setSelectedImage = match$1[1];
  var selectedImage = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setIsFullscreenCarouselOpen = match$2[1];
  var isFullscreenCarouselOpen = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setIsFullscreenCarouselVisible = match$3[1];
  var isMobile = MediaQuery.isMobile();
  var onSelectImage = function (i) {
    setSelectedImage(function (param) {
          return i;
        });
    setIsFullscreenCarouselOpen(function (param) {
          return true;
        });
  };
  var onAllImagesClose = React.useCallback((function () {
          setIsAllImagesOpen(function (param) {
                return false;
              });
        }), [setIsAllImagesOpen]);
  var imagesForCarousel = images.map(function (image) {
        return {
                fullscreen: image.carouselFullscreen,
                large: image.carouselLarge,
                small: image.carouselSmall,
                thumb: image.carouselThumb
              };
      });
  if (images.length <= 0) {
    return null;
  }
  var match$4 = isFullscreenCarouselOpen || isAllImagesOpen;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(AllImages.make, {
                      isOpen: isAllImagesOpen,
                      onClose: onAllImagesClose,
                      images: images.map(function (image) {
                            return image.fragmentRefs;
                          }),
                      onSelectImage: onSelectImage,
                      isFullscreenCarouselVisible: match$3[0],
                      isFullscreenCarouselOpen: isFullscreenCarouselOpen,
                      selectedImage: selectedImage
                    }),
                JsxRuntime.jsx(TMPUIKIT____ImgGallery.CarouselDialog.make, {
                      isOpen: isFullscreenCarouselOpen,
                      onDismiss: (function () {
                          setIsFullscreenCarouselOpen(function (param) {
                                return false;
                              });
                        }),
                      children: JsxRuntime.jsx(TMPUIKIT____ImgGallery.Carousel.make, {
                            images: imagesForCarousel,
                            onSelect: (function (i) {
                                setSelectedImage(function (param) {
                                      return i;
                                    });
                              }),
                            selectedIndex: Core__Option.getOr(selectedImage, 0),
                            imgSize: "fullscreen"
                          }),
                      transitionOnDestroyed: (function () {
                          setIsFullscreenCarouselVisible(function (param) {
                                return isFullscreenCarouselOpen;
                              });
                        })
                    }),
                isMobile ? (
                    match$4 ? JsxRuntime.jsx(TMPUIKIT____ImgGallery.ResponsiveImage.make, {
                            image: imagesForCarousel[Core__Option.getOr(selectedImage, 0)],
                            alt: "",
                            imgSize: "small"
                          }) : JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(TMPUIKIT____ImgGallery.LightCarousel.make, {
                                    images: imagesForCarousel,
                                    openModal: (function () {
                                        setIsFullscreenCarouselOpen(function (param) {
                                              return true;
                                            });
                                      }),
                                    onSelect: (function (i) {
                                        setSelectedImage(function (param) {
                                              return i;
                                            });
                                      }),
                                    selectedIndex: Core__Option.getOr(selectedImage, 0)
                                  }),
                              JsxRuntime.jsx(ImageGallery$MobileViewAllButton, {
                                    onPress: (function (param) {
                                        setIsAllImagesOpen(function (param) {
                                              return true;
                                            });
                                      })
                                  })
                            ],
                            className: "relative"
                          })
                  ) : JsxRuntime.jsx(MarkupHelpers.Container.make, {
                        children: JsxRuntime.jsx(ImageGrid.make, {
                              images: images.map(function (image) {
                                    return image.fragmentRefs;
                                  }),
                              onViewAll: (function (param) {
                                  setIsAllImagesOpen(function (param) {
                                        return true;
                                      });
                                }),
                              onSelectImage: onSelectImage
                            })
                      })
              ]
            });
}

var Gallery;

var CarouselImg;

var make = ImageGallery;

exports.ImageFragment = ImageFragment;
exports.Gallery = Gallery;
exports.CarouselImg = CarouselImg;
exports.MobileViewAllButton = MobileViewAllButton;
exports.make = make;
/* react Not a pure module */
