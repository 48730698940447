// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Spring = require("../../../../v5/common/bindings/Spring.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var PervasivesU = require("rescript/lib/js/pervasivesU.js");
var ReactRender = require("../../../../reason/utils/ReactRender.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var ReactSpring = require("react-spring");
var JsxRuntime = require("react/jsx-runtime");
var StylesJs = require("../../../reason/molecules/Dialog/styles.js");

var overlay = Css.style({
      hd: Css.important(Css.unsafe("zIndex", "9999999999")),
      tl: {
        hd: Css.height({
              NAME: "vh",
              VAL: 100
            }),
        tl: /* [] */0
      }
    });

var Styles = {
  overlay: overlay
};

var make = StylesJs.BaseContent;

var Content = {
  make: make
};

var make$1 = StylesJs.BaseOverlay;

var JS = {
  make: make$1
};

function make$2(props) {
  var newrecord = Caml_obj.obj_dup(props);
  return JsxRuntime.jsx(make$1, (newrecord.className = Core__Option.getOr(Core__Option.map(props.className, (function (c) {
                          return Css.merge({
                                      hd: overlay,
                                      tl: {
                                        hd: c,
                                        tl: /* [] */0
                                      }
                                    });
                        })), overlay), newrecord));
}

var Overlay = {
  JS: JS,
  make: make$2
};

var make$3 = StylesJs.AnimatedBaseContent;

var AnimatedContent = {
  make: make$3
};

var make$4 = StylesJs.AnimatedBaseOverlay;

var JS$1 = {
  make: make$4
};

function make$5(props) {
  var newrecord = Caml_obj.obj_dup(props);
  return JsxRuntime.jsx(make$4, (newrecord.className = Core__Option.getOr(Core__Option.map(props.className, (function (c) {
                          return Css.merge({
                                      hd: overlay,
                                      tl: {
                                        hd: c,
                                        tl: /* [] */0
                                      }
                                    });
                        })), overlay), newrecord));
}

var AnimatedOverlay = {
  JS: JS$1,
  make: make$5
};

var Transition = Spring.MakeTransition({});

function UIKit__BaseDialog$Animated(props) {
  var children = props.children;
  var closeButton = props.closeButton;
  var contentClassName = props.contentClassName;
  var overlayClassName = props.overlayClassName;
  var __onDismiss = props.onDismiss;
  var ariaLabel = props.ariaLabel;
  var onDismiss = __onDismiss !== undefined ? __onDismiss : (function (prim) {
        
      });
  var transitions = ReactSpring.useTransition([props.isOpen], (function (item) {
          return PervasivesU.string_of_bool(item);
        }), {
        from: {
          opacity: "0",
          transform: "translateY(-20px) scale(0.98)"
        },
        enter: {
          opacity: "1",
          transform: "translateY(0) scale(1)"
        },
        leave: {
          opacity: "0",
          transform: "translateY(20px) scale(1.1)"
        },
        onDestroyed: props.transitionOnDestroyed,
        config: {
          tension: 600,
          friction: 40
        }
      });
  return transitions.map(function (param) {
              if (!param.item) {
                return null;
              }
              var props = param.props;
              var style_opacity = props.opacity;
              var style_transform = props.transform;
              var style = {
                opacity: style_opacity,
                transform: style_transform
              };
              return JsxRuntime.jsx(make$5, {
                          isOpen: true,
                          className: overlayClassName,
                          style: {
                            opacity: props.opacity
                          },
                          onDismiss: onDismiss,
                          children: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  ReactRender.fromOption(closeButton, (function (button) {
                                          return button;
                                        })),
                                  JsxRuntime.jsx(make$3, {
                                        ariaLabel: ariaLabel,
                                        className: contentClassName,
                                        style: style,
                                        children: children
                                      })
                                ]
                              })
                        }, param.key);
            });
}

var Animated = {
  make: UIKit__BaseDialog$Animated
};

exports.Styles = Styles;
exports.Content = Content;
exports.Overlay = Overlay;
exports.AnimatedContent = AnimatedContent;
exports.AnimatedOverlay = AnimatedOverlay;
exports.Transition = Transition;
exports.Animated = Animated;
/* overlay Not a pure module */
